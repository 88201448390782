import React, { Component } from "react";
import openSocket from "socket.io-client";
import CryptoJS from "react-native-crypto-js";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
//import GenerateCred from "./components/GenerateCred/generateCred";
import VerifyCreditnals from "./components/VerifyCreditnals/generateCred";
import data from "./api";
import { store } from "react-notifications-component";
import "./App.css";
import Swal from "sweetalert2";
import logo from "./images/images/earthid-logo.png";

import advice from "./images/images/advice.jpg";

import facebook from "./images/assets/footer/facebook.png";
import twitter from "./images/assets/footer/twitter.png";
import instagram from "./images/assets/footer/instagram.png";
import linkedin from "./images/assets/footer/linkedin.png";
import youtube from "./images/assets/footer/youtube.png";
import mail from "./images/assets/footer/mail.png";

export const isTestnet = false;
const socketURL =
  isTestnet === true
    ? "https://socket-stage.myearth.id"
    : "https://socket.myearth.id";
const socket = openSocket(socketURL, {
  transports: ["websocket", "polling", "flashsocket"],
});
let socketId;
socket.on("connect", () => {
  socketId = socket.id;
  console.log(`I'm connected with the back-end`);
});


const {
  listenForUserData,
  listenForServiceProviderResponse,
  getApiKey,
  issuerDidgenerateqr,
  createIssuerDid,
  getUserCreditnals,
  generateqr, verifier_listenForUserData,
  verifier_listenForServiceProviderResponse,
  verifier_generateqr,
  verifyCreditnals
} = data;
let showNotification = true;

class App extends Component {
  state = {
    showSpinner: false,
    showBtn: true,
    requestNo: "",
    qrcode: false,
    response: false,
    email: "",
    mno: "",
    profileDrop: false,
    btnText: "Login with Earth Id",
    showText: true,
    showModal: true,
    modalHeading: false,
    createDid: "",
    creditnals: "",
  };

  loader = () => {
    this.setState({ showBtn: false, modalHeading: true });
    this.setState({ qrcode: true, showSpinner: false });
    if (!this.state.showModal) {
      this.setState({
        showModal: true,
      });
    }
    if (this.state.loggedIn) {
      localStorage.removeItem("email");
      localStorage.removeItem("mobile_no");
      localStorage.removeItem("user_name");
      localStorage.removeItem("dob");
      localStorage.removeItem("e_verified");
      localStorage.removeItem("trust_score");
      localStorage.removeItem("earth_id");
      localStorage.removeItem("m_verified");
      this.setState({
        loggedIn: false,
        btnText: "Login with Earth Id",
        qrcode: true,
        response: false,
        showModal: false,
      });
      setTimeout(() => {
        document.getElementById("exampleModal").click();
      }, 0);
      this.redirectToHome();
    }
  };

  componentDidMount = async () => {
    if (localStorage.email != null) {
      this.setState({
        loggedIn: true,
        btnText: "LogOut",
      });
    }
    if (!this.state.apiKey) {
      this.state.apiKey = getApiKey();
    }
  };

  updateNotificationState = () => {
    setTimeout(() => {
      showNotification = true;
    }, 100);
    this.redirectToGenerateCred();
  };

  verifyNotificationState = () => {
    setTimeout(() => {
      showNotification = true;
    }, 100);
    this.redirectToVerifyCreditnals();
  };

  userDenied = () => {
    this.setState({
      response: false,
      showModal: false,
    });
    setTimeout(() => {
      document.getElementById("exampleModal").click();
      if (showNotification === true) {
        showNotification = false;
        store.addNotification({
          title: "Error!",
          message: `User Didn't Authorize!`,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      }
    }, 0);
  };

  listenForSocket = () => {
    listenForServiceProviderResponse((err, res) => {
      this.setState({
        qrcode: false,
        response: false,
        showSpinner: true,
        showText: false,
      });
    });

    listenForUserData(async (err, res) => {
      let bytes = res.userDetails || res.newreq;
      console.log("bytes", bytes);
      this.setState({
        showQr: false,
        showSpinner: false,
      });
      let creditnals;
      let sessionKey;
      if (bytes === "denied" || bytes.permission === "denied") {
        this.userDenied();
      } else if (
        bytes.permission === "granted" ||
        bytes === "issuerLogin" ||
        bytes === "credentialsgenerated" ||
        bytes === "verifylogin" ||
        bytes === "success"
      ) {
        this.setState({
          response: true,
          showSpinner: false,
          loggedIn: true,
          showModal: false,
          btnText: "LogOut",
        });
        setTimeout(() => {
          document.getElementById("exampleModal").click();
          let popMessage;
          if (showNotification === true) {
            if (bytes === "credentialsgenerated") {
              popMessage = "Credentials Generated";
            } else {
              popMessage = `Login Successful`;
            }
            showNotification = false;
            store.addNotification({
              title: "Success!",
              message: popMessage,
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 3000,
                onScreen: true,
              },
            });
            if (bytes.permission === "granted") {
              this.updateNotificationState();
            } else if (bytes === "verifylogin") {
              this.verifyNotificationState();
            }
          }
        }, 0);
      } else if (bytes.userDetails.credentialType != null) {
        console.log("bytes", bytes);
        sessionKey = bytes.sessionKey;
        await getUserCreditnals(bytes.userDetails)
          .then((data) => {
            this.userCreditnalsSuccess(bytes);

            creditnals = data;
          })
          .catch((err) => {
            alert(err);
          });
      } else {
        this.setState({
          response: false,
          showModal: false,
        });
        setTimeout(() => {
          document.getElementById("exampleModal").click();
          if (showNotification === true) {
            showNotification = false;
            store.addNotification({
              title: "Error!",
              message: `User Didn't Authorize!`,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 3000,
                onScreen: true,
              },
            });
          }
        }, 0);
      }
    });
  };

  userCreditnalsSuccess = (data) => {
    if (data.userDetails.credentialType != null) {
      this.setState({
        response: true,
        showSpinner: false,
        loggedIn: true,
        showModal: false,
        btnText: "LogOut",
      });
      setTimeout(() => {
        document.getElementById("exampleModal").click();
        let popMessage;
        if (showNotification === true) {
          popMessage = "Credentials Generated";
          showNotification = false;
          store.addNotification({
            title: "Success!",
            message: popMessage,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 3000,
              onScreen: true,
            },
          });
        } else {
          this.setState({
            response: false,
            showModal: false,
          });
          setTimeout(() => {
            document.getElementById("exampleModal").click();
            if (showNotification === true) {
              showNotification = false;
              store.addNotification({
                title: "Error!",
                message: `User Didn't Authorize!`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                  duration: 3000,
                  onScreen: true,
                },
              });
            }
          }, 0);
        }
      }, 0);
    }
  };

  generateQrCodeData = async () => {
    issuerDidgenerateqr("login", "loginDetailsEncryption")
      .then(async (data) => {
        this.listenForSocket();
        this.setState({
          showQr: true,
          qrCodeData: data,
          apiKey: this.state.apikey,
          qrcode: false,
        });
      })
      .catch((err) => {
        alert(err);
      });
  };

  generateCredQrCodeData = () => {
    issuerDidgenerateqr("generateCredentials")
      .then((data) => {
        this.listenForSocket();
        this.setState({
          showQr: true,
          qrCodeData: data,
          apiKey: this.state.apikey,
          qrcode: false,
        });
      })
      .catch((err) => {
        alert(err);
      });
  };



  qrcode = () => {
    let { qrCodeData } = this.state;
    return (
      <div className="qr-container">
        <img src={qrCodeData} alt="new" />
      </div>
    );
  };

  redirectToHome() {
    window.location.href = "/";
  }
  redirectToGenerateCred() {
    window.location.href = "/generateCred";
  }

  redirectToVerifyCreditnals() {
    window.location.href = "/verifyCreditnals";
  }

  //VERIFY CREDENTIALS

  verifyQrCodeData = () => {
    verifier_generateqr("shareCredentials")
      .then((data) => {
        // console.log("Error found")
        this.verifier_listenForSocket();
        this.setState({
          showQr: true,
          qrCodeData: data,
          apiKey: this.state.apikey,
          qrcode: false,
        });
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  verifier_listenForSocket  = () => {
    verifier_listenForServiceProviderResponse((err, res) => {
      this.setState({
        qrcode: false,
        response: false,
        showSpinner: true,
        showText: false,
      });
    });

  verifier_listenForUserData(async (err, res) => {
    let bytes = res.userDetails || res.newreq;
    console.log("bytes", bytes);
    console.log("bytes", `${this.props.location}${bytes}`);
    if (bytes.permission === "denied" || bytes === "denied") {
      this.userDenied();
    } else {
      Swal.fire({
        // title: 'Are you sure?',
        text: bytes.credential,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Verify",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            position: "center",
            //icon: 'success',
            title: "Verifying....",
            showConfirmButton: false,
          });
          verifyCreditnals(
            bytes.credential,
            bytes.publicKey,
            bytes.signature,
            bytes.verifyParams
          ).then((data) => {
            if (data.success === true) {
              Swal.fire("Successfully Verified");
            } else {
              Swal.fire("Verification Failed");
            }
          });
        }
      });
      this.setState({
        showQr: false,
        showSpinner: false,
      });
      if (bytes != null) {
        this.setState({
          response: true,
          showSpinner: false,
          loggedIn: true,
          showModal: false,
          btnText: "LogOut",
        });
        setTimeout(() => {
          document.getElementById("exampleModal").click();
          let popMessage;
          if (showNotification === true) {
            if (bytes === "credentialsgenerated") {
              popMessage = "Credentials Generated";
            } else {
              popMessage = `Credentials Received Successfully`;
            }
            showNotification = false;
            store.addNotification({
              title: "Success!",
              message: popMessage,
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 3000,
                onScreen: true,
              },
            });
            if (bytes == "login") {
              this.updateNotificationState();
            } else if (bytes === "verifylogin") {
              this.verifyNotificationState();
            }
          }
        }, 0);
      } else {
        this.setState({
          response: false,
          showModal: false,
        });
        setTimeout(() => {
          document.getElementById("exampleModal").click();
          if (showNotification === true) {
            showNotification = false;
            store.addNotification({
              title: "Error!",
              message: `User Didn't Authorize!`,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 3000,
                onScreen: true,
              },
            });
          }
        }, 0);
      }
    }
  });
};

  render() {
    let showButton = this.state.showSpinner ? (
      <div className="spinner-div">
        <img className="spinner" src={require("./spinner.gif")} />
      </div>
    ) : null;
    return (
      <div className="App">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div
            className=" collapse navbar-collapse pull-right "
            id="navbarTogglerDemo01"
          >
            <div
              className="clickable"
              onClick={() => window.open("https://www.myearth.id/")}
            >
              <img src={logo} alt="logo" className="logo" />{" "}
              {/* <span className="logo-text">&nbsp;Issuer</span> */}
            </div>
            <ul
              className=" navbar-nav mr-auto mt-2 mt-lg-0"
              style={{
                marginLeft: "1.5rem",
                textTransform: "uppercase",
                color: "White",
              }}
            >
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="#">
                  Discover
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Book
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Contact us
                </a>
              </li>
            </ul>

            {localStorage.user_name ? (
              <div>
                <label className="welcomeLabel">{`Welcome, ${localStorage.getItem(
                  "user_name"
                )}`}</label>
              </div>
            ) : null}

            <div className="profileAccess">
              {window.location.pathname == "/" ? (
                <div>
                  <button
                    type="button"
                    className="nav-buttons"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    onClick={this.generateQrCodeData}
                  >
                    {this.state.btnText}
                  </button>
                  <button
                    type="button"
                    className="nav-buttons"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    onClick={this.generateCredQrCodeData}
                    style={{ marginRight: "2rem", marginLeft: "2rem" }}
                  >
                    Generate Credentials
                  </button>
                  <button
                    type="button"
                    className="nav-buttons"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    onClick={this.verifyQrCodeData
                    }
                  >
                    Verify Credentials
                  </button>
                </div>
              ) : window.location.pathname == "/" ? (
                <div>
                  <button
                    style={{ marginRight: "300px" }}
                    type="button"
                    className="nav-buttons"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    onClick={this.verifyQrCodeData
                    }
                  >
                    Verify Credentials
                  </button>
                  {" "}
                  <button
                    type="button"
                    className="nav-buttons"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    onClick={this.redirectToHome}
                  >
                    Logout
                  </button>
                </div>
              ) : (
                <div>
                  <button
                    type="button"
                    className="nav-buttons"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    onClick={this.generateCredQrCodeData}
                    style={{ marginRight: "2rem", marginLeft: "2rem" }}
                  >
                    {" "}
                    Generate Credentials
                  </button>{" "}
                  <button
                    type="button"
                    className="nav-buttons"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    onClick={this.redirectToHome}
                  >
                    Logout
                  </button>
                </div>
              )}

              {this.state.loggedIn ? (
                <div className="dropDown">
                  {this.state.profileDrop ? (
                    <div>
                      <ul className="dropdown-profile">
                        <li
                          className="dropdown-list1"
                          onClick={() => this.redirectToProfile()}
                        >
                          My Profile
                        </li>
                        <li
                          className="dropdown-list1"
                          onClick={() => this.redirectToDocuments()}
                        >
                          {" "}
                          Submit Kyc Documents
                        </li>
                        <li
                          className="dropdown-list1"
                          onClick={() => this.redirectToGenerateCred()}
                        >
                          {" "}
                          Generate Credentials
                        </li>
                        <li className="dropdown-list" onClick={this.loader}>
                          LogOut
                        </li>
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </nav>

        {/* <header> */}
        {/* <a href="https://www.myearth.id/" target="blank" className="logo__link">
            <img className="logo" src={logo} alt="brand_logo" />
        </a>

        <nav className="nav__links">
            <ul>
                <li><a href="">Services</a></li>
                <li><a href="">Business</a></li>
                <li><a href="">Corporates & Institutions</a></li>
                <li><a href="">Banking with us</a></li>

            </ul>
        </nav> */}

        {/* <div className="nav__buttons">
            <button data-modal-target="#modal">Request Credentials</button>
    <div className="modal" id="modal">
        
        <div className="modal-header">
            <div className="title">Waiting for user authorization</div>
        </div>

        <div className="modal-body">
            <img src="https://api.qrserver.com/v1/create-qr-code/?data={&quot;apikey&quot;:&quot;UKURGTREFATPSYJ&quot;,&quot;reqNo&quot;:&quot;BAZQUVRDTLF&quot;,&quot;sessionKey&quot;:&quot;BAZQUVRDTLF&quot;,&quot;requestType&quot;:&quot;login&quot;,&quot;encryptionkey&quot;:&quot;loginDetailsEncryption&quot; }" alt="QR-Code" />
            <div className="close-button-div">
                <button data-close-button className="close-button">Close</button>
            </div>
        </div>
    </div>

    <div id="overlay"></div>
            <a href="#" className="cta-1 cta-2"><button className="cta__buttons">Login with EarthiD</button></a>
        </div> */}
        {/* </header> */}
        {/* <header> */}
        <div class="hero-content">
          <div class="header-contents">
            <h2 class="hero-header">SYDNEY CALLING !</h2>
            <p class="hero-text">
              Plan an exciting city break and explore iconic cultural landmarks.
              Book your trip to Sydney now at an exciting price.
            </p>
            <div class="form">
              <form>
                <input className="form-text" type="text" placeholder="From" />
                <input className="form-text" type="email" placeholder="To" />
                <input
                  className="form-text"
                  type="submit"
                  value="Check Flights"
                />
              </form>
            </div>
          </div>
        </div>

        <section className="features">
          <div className="feature-section">
            <div className="feature__row">
              <div className="feature-box feature-box-1 ">
                <div className="feature-box-text-div">
                  <h2 className="feature-box__text">New York</h2>
                </div>
              </div>

              <div className="feature-box feature-box-2 ">
                <div className="feature-box-text-div">
                  <h2 className="feature-box__text">London</h2>
                </div>
              </div>
            </div>

            <div className="feature__row feature__row-2">
              <div className="feature-box feature-box-3 ">
                <div className="feature-box-text-div">
                  <h2 className="feature-box__text">Los Angeles</h2>
                </div>
              </div>

              <div className="feature-box feature-box-4 ">
                <div className="feature-box-text-div">
                  <h2 className="feature-box__text">Dubai</h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="action-footer">
          <div className="footer">
            <div className="footer__text__box">
              <h2 className="footer__text">Connect with us</h2>
            </div>

            <div className="footer__icon__div">
              <div className="footer__icon__box">
                <a
                  className="footer__icon "
                  href="https://twitter.com/earthid_ssi"
                  target="blank"
                >
                  <img
                    className="footer__icon footer__icon__1"
                    src={twitter}
                    alt="Icon 1"
                  />
                </a>
              </div>

              <div className="footer__icon__box">
                <a
                  className="footer__icon "
                  href="https://www.facebook.com/myearth.id/"
                  target="blank"
                >
                  <img
                    className="footer__icon__2"
                    src={facebook}
                    alt="Icon 2"
                  />
                </a>
              </div>

              <div className="footer__icon__box">
                <a
                  className="footer__icon "
                  href="https://www.linkedin.com/company/myearthid/"
                  target="blank"
                >
                  <img className="footer__icon_3" src={linkedin} alt="Icon 3" />
                </a>
              </div>

              <div className="footer__icon__box">
                <a
                  className="footer__icon"
                  href="https://www.youtube.com/channel/UCaRjLyN4I_0UrK3NL4zU1Vg/featured"
                  target="blank"
                >
                  <img className="footer__icon_4" src={youtube} alt="Icon 4" />
                </a>
              </div>

              <div className="footer__icon__box">
                <a
                  className="footer__icon"
                  href="https://www.instagram.com/myearthid/"
                  target="blank"
                >
                  <img
                    className=" footer__icon__5"
                    src={instagram}
                    alt="Icon 5"
                  />
                </a>
              </div>

              <div className="footer__icon__box">
                <a className="footer__icon" href="" target="blank">
                  <img className=" footer__icon__6" src={mail} alt="Icon 6" />
                </a>
              </div>
            </div>
          </div>
        </section>

        <div className="modal_container" id="modal-container">
          <div className="modal">
            <div className="qr-code">
              <img
                src='https://api.qrserver.com/v1/create-qr-code/?data={"apikey":"UKURGTREFATPSYJ","reqNo":"BAZQUVRDTLF","sessionKey":"BAZQUVRDTLF","requestType":"login","encryptionkey":"loginDetailsEncryption" }'
                alt="QR-Code"
              />
            </div>

            <button id="btn-close">Close me</button>
          </div>
        </div>
        {/* </header> */}

        {window.location.pathname == "/" ? (
          <div className="hero-section">
            <div className="shoppingCart"></div>
            <div
              className="modal fade"
              id="exampleModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                {
                  <div>
                    {this.state.showModal ? (
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 style={{ color: "black" }}>
                            {this.state.modalHeading && !this.state.showSpinner
                              ? "Use Earth ID app to scan the QR code"
                              : "Waiting for user authorization"}
                          </h4>
                        </div>
                        <div className="modal-body">
                          <div className="pop-upbox">
                            {showButton}
                            {this.state.response
                              ? null
                              : this.state.qrcode
                                ? this.generateQrCodeData()
                                : null}
                            {this.state.showQr ? (
                              this.qrcode()
                            ) : this.state.showSpinner ? (
                              <img
                                className="spinner"
                                src={require("./spinner.gif")}
                              />
                            ) : null}
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="close-button"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                }
              </div>
            </div>
          </div>
        ) : (
          <div className="hero-section">
            <div className="shoppingCart"></div>
            <div
              className="modal fade"
              id="exampleModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                {
                  <div>
                    {this.state.showModal ? (
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4>
                            {this.state.modalHeading && !this.state.showSpinner
                              ? "Use Earth ID app to scan the QR code"
                              : "Waiting for user authorization"}
                          </h4>
                        </div>
                        <div className="modal-body">
                          <div className="pop-upbox">
                            {showButton}
                            {this.state.response
                              ? null
                              : this.state.qrcode
                                ? this.generateCredQrCodeData()
                                : null}
                            {this.state.showQr ? (
                              this.qrcode()
                            ) : this.state.showSpinner ? (
                              <img
                                className="spinner"
                                src={require("./spinner.gif")}
                              />
                            ) : null}
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary p-btn"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                }
              </div>
            </div>
          </div>
        )}
        <div className="content">
          {/* <Router>
              <Switch>
                <Route path="/generateCred" component={GenerateCred} />
                <Route path="/verifyCreditnals" component={VerifyCreditnals} />
              </Switch>
            </Router> */}
        </div>
      </div>
    );
  }
}

export default App;
